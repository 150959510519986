$accentRed: #d50000;
.wrapper {
    margin-top: 75px;
}

.custombutton {
    width: 20%;
    min-width: 200px;
    margin-top: 10px;
}
.registerText {
    margin: auto;
    width: 35%;
    min-width: 300px;
}
.accentText {
    color: $accentRed;
}
.accentTextBig {
    color: $accentRed;
    font-size: 35px;
}
.ButtonText {
    color: #ffffff;
}
.footerRule {
    margin: 0;
    min-height: 1px;
    width: 100%;
}

.registerInput {
    width: 20%;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 3px solid #d50000;
    --background: #121212 !important;
    --color: #ffffff !important;
    --placeholder-color: #ffffff !important;
}
.copyRight {
    color: #999999;
    padding-bottom: 3.5em;
}

.facultySelect {
    width: 20%;
    margin: 10px auto 0;
    min-width: 200px;
    color: #d50000;
}

.majorSelect {
    width: 20%;
    margin: 10px auto 0;
    min-width: 200px;
    color: #d50000;
}
