.singlePost {
    height: 80vh;
    overflow-y: scroll;
}

.subjectChip {
    float: left;
    border-radius: 3%;
    order: 12;
}

.postAvatar {
    background-color: --ion-color-primary-contrast;
    margin-right: 1em;
    order: 1;
}

.postName {
    text-align: left;
    flex-grow: 5;
}

.postDescription {
    text-align: left;
    order: 6;
}

.postInfo {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
}
