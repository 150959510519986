.wrapper {
    margin-top: 75px;
}

.footer {
    height: 1px;
}

.videoContainer {
    --border-style: none;
}

.videoPlaceholder {
    margin: auto;
    width: 400px;
    height: 400px;
}

.placeholderBlock {
    text-align: center;
    margin: auto;
    width: 240px;
    height: 240px;
}
