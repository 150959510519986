.wrapper {
    margin-top: 75px;
}

.footer {
    height: 1px;
}

.bottomOfPage {
    margin-bottom: 30px;
}

.videoPlaceholder {
    margin: auto;
    width: 150px;
    height: 150px;
}

.follow {
    margin-bottom: 30px;
    margin-top: 30px;
}

.multiline {
    white-space: pre-wrap;
}

div {
    text-align: center;
}

p {
    color: #ffffff;
}
