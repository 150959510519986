.commentContainer {
    margin: 10px;
}

.commentItem {
    margin-top: 20px;
    margin-bottom: 20px;
}

.commentContent {
    float: left;
    text-align: left;
}

.commentAvatar {
    background-color: black;
    margin: 10px;
}
