.options {
    float: right;
    border-color: black;
    border: 2px;
}

.image {
    width: 120px;
    height: 120px;
}

.content {
    max-height: 385px;
}

.listHeader {
    font-weight: bold;
    font-size: 1.5em;
}
.item {
    --color-hover: black;
}
