.learnMore {
    --background: transparent;
    --color: #d50000;
    --border-style: solid;
    --border-color: #d50000;
    --border-width: 2px;
    --border-radius: 5px;
}

.landingParagraphs {
    display: block;
    text-align: left;
}
